import { Injectable } from '@angular/core';
import {
    Firestore,
    Timestamp,
    DocumentReference,
    doc,
    docSnapshots,
    CollectionReference,
    collection,
    collectionChanges,
    Query,
    docData,
    collectionData,
    addDoc,
    getDoc,
    getDocs,
    DocumentSnapshot,
    setDoc
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { Auth, signInWithEmailAndPassword, signOut } from '@angular/fire/auth';
import { isEqual } from 'lodash';
import { environment } from '../../../environments/environment';

type DocPredicate<T> = string | DocumentReference<T>;
type CollectionPredicate<T> = string | CollectionReference<T>;

@Injectable({
    providedIn: 'root'
})
export class FirestoreService {

    constructor(private angularFirestore: Firestore,
        private angularFireAuth: Auth) {
        this.signIn();
    }

    async signIn() {
        await signInWithEmailAndPassword(this.angularFireAuth, environment.firebaseAuth.email, environment.firebaseAuth.password);
    }

    async signOut() {
        await signOut(this.angularFireAuth);
    }

    serverDateTime() {
        let dateTimeNow = Timestamp.now().toDate();
        return dateTimeNow ? dateTimeNow : new Date();
    }

    doc<T>(ref: DocPredicate<T>): DocumentReference<T> {
        return typeof ref === 'string' ? doc(this.angularFirestore, ref) as DocumentReference<T> : ref;
    }

    docSnapshots$<T>(ref: DocPredicate<T>): Observable<T> {
        return docSnapshots<T>(this.doc(ref)).pipe(map(doc => {
            return doc.data() as T;
        }))
            .pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr)));
    }

    async getDocSnapshotAsync<T>(ref: DocPredicate<T>): Promise<DocumentSnapshot<T>> {
        return await getDoc(this.doc(ref));
    }

    async getDocValueAsync<T>(ref: DocPredicate<T>): Promise<T | undefined> {
        return (await getDoc(this.doc(ref))).data();
    }

    docValueChanges$<T>(ref: DocPredicate<T>): Observable<T | undefined> {
        return docData<T>(this.doc(ref))
            .pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr)));
    }

    col<T>(ref: CollectionPredicate<T>): CollectionReference<T> {
        return typeof ref === 'string' ? collection(this.angularFirestore, ref) as CollectionReference<T> : ref;
    }

    colSnapshots$<T>(queryRef: Query<T>): Observable<T[]> {
        return collectionChanges<T>(queryRef).pipe(map(docs => {
            return docs.map(a => a.doc.data()) as T[];
        }))
            .pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr)));
    }

    async getCollectionAsync<T>(queryRef: Query<T>): Promise<T[]> {
        return (await getDocs(queryRef)).docs.map(doc => {
            return doc.data();
        });
    }

    colValueChanges$<T>(queryRef: Query<T>): Observable<T[]> {
        return collectionData<T>(queryRef)
            .pipe(distinctUntilChanged((prev, curr) => isEqual(prev, curr)));
    }

    async addDoc<T>(reference: CollectionReference<T>, data: T): Promise<DocumentReference<T>> {
        const plainObject = Object.fromEntries(Object.entries(data as any).filter(([_, v]) => v !== undefined));
        return addDoc(reference, plainObject as any);
    }

    async setDoc<T>(reference: DocumentReference<T>, data: T): Promise<void> {
        const plainObject = Object.fromEntries(Object.entries(data as any).filter(([_, v]) => v !== undefined));
        return setDoc(reference, plainObject as any);
    }

}